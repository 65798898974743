<!-- Send Msg Form -->
<div class="border-0 border-rad p-4 card-shadow">
   <div class="mb-4">
      <h2> Send Us Message </h2>
   </div>
   <form [formGroup] = "sendMessageForm" (ngSubmit) = "sendMessage(sendMessageForm.value)">

      <datalist id="purposeList">
         <option value="サービスについて">  
         <option value="採用について">
         <option value="その他お問合せ">
      </datalist>

      <div class="form-group mb-4">
         <label class="mb-3" for="purpose">お問合せ目的</label>
         <input id="purpose" type="text" class="form-control" placeholder="" formControlName = "purpose" list="purposeList" required>
      </div>
      <div class="form-group mb-4">
         <label class="mb-3" for="name">企業名 or お名前</label>
         <input id="name" type="text" class="form-control" placeholder="" formControlName = "name" required>
      </div>
      <div class="form-row">
         <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label class="mb-3" for="phone_number">電話番号</label>
            <input id="phone_number" type="text" class="form-control" placeholder="" formControlName = "phone_number">
         </div>
         <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label class="mb-3" for="email-address">Email</label>
            <input id="email-address" type="email" class="form-control" placeholder="" formControlName = "email" required>
         </div>
      </div>
      <div class="form-group mb-5">
         <label class="mb-3" for="message">Messages</label>
         <textarea id="message" class="form-control" rows="5" placeholder="" formControlName = "textarea" required></textarea>
      </div>
      <div class="form-group">
         <button type="submit" class="btn btn-dark btn-chk-dark inverse">Send Messages</button>
      </div>
   </form>
</div>
