<div class="page-main-container">
	<!-- Service grid -->
	<div id="home-services" class="service section-gap">
		<div class="container">
			<div class="section-title-wrapper">
			  <div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2>ITを通して生き抜く力を養い</h2>
						<h2>豊かな社会を実現する</h2>
						<p class="mb-0">成長し続けるIT業界で未来ある世代が充分に活躍できる場を創出し社員一人一人が生き抜く力を以て社会に貢献し続けることを目指していきます。</p>
					</div>
			  </div>
			</div>
			<div class="row" angly-serviceGrid [serviceGridContent]="services"></div>
		</div>
	</div>
	<!-- Video section -->
	<!-- <div class="chk-video-section section-gap bg-light">
		<div class="container">
		  <div class="row" angly-chkVideo [videoContent]="videoContent">
		  </div>
		</div>
	</div> -->
	<!-- Our Goles and mission  -->
	<div class="our-mission bg-light">
	<br>
		<div class="container">
			<div class="blog-alternate mb-5" *ngFor="let content of about">
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-7">
						<div class="overlay-wrap">
							<img [src]="content?.image_path" width="370" height="300" alt="about-img" class="img-fluid w-100 border-rad shadow-md" />
							<div class="card-img-overlay primary-tp-layer pos-center text-center">
								<div class="center-holder">
									<a href="javascript:void(0)" class="ih-fade-right"><i class="fa fa-arrow-right fa-3x fa-inverse"></i></a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-12 col-lg-5">
						<div class="about-content py-5 px-4">
							<div class="mb-3">
								<h2 class="font-2x m-0">{{content?.heading}}</h2>
							</div>
							<p>
								{{content?.content}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Featured section -->
	<!-- <div class="mob-feature">
		<div class="section-gap">
			<div class="container">
				<div class="section-title-wrapper">
				  <div class="row">
						<div class="col-sm-12 col-md-10 mx-auto text-center text-white">
							<p class="mb-0">オセロフィナンシャルではエンジニア教育に力を入れ、若手の人材採用・エンジニア教育・エンジニア創出に力を入れています。</p>
						</div>
				  </div>
				</div>
				<div class="row" angly-mobileFeatured [mobileFeatured]="mobileFeatured"></div>
			</div>
		</div>
	</div> -->
	<!-- Projects grid-->
	<!-- <div class="Gallery-grid section-gap">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2> Our Awesome Projects </h2>
						<p class="mb-0">
						  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
						  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
						</p>
					</div>
				</div>
			</div>
			<div class="row" angly-gallaryGrid gridClass="col-sm-12 col-md-4 mb-4" [gallaryGridContent]="projectGallary"></div>
		</div>
	</div> -->
	<!-- Team grid -->
	<!-- <div id="home-team" class="team section-gap">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2> Our Team </h2>
						<p class="mb-0">Meet our professional and experienced team, each team member is highly skilled and master in their area.</p>
					</div>
				</div>
			</div>
			<div class="row" angly-teamGrid [teamGridContent]="team" ></div>
		</div>
	</div> -->

	<!-- feature slide section -->
	<div class="feature-work section-gap bg-light">
		<div class="container" *ngIf="featuresContent?.features_work">
			<div class="section-title-wrapper mb-5">
				<div class="row">
						<div class="col-sm-12 col-md-10 mx-auto text-center">
							<h2>現在募集している職種</h2>
							<!-- <p>
								This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information.
							</p> -->
						</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3" *ngFor="let content of featuresContent?.features_work" >
						<div class="card-line-box  p-4">
							<div class="mb-4">
								<img [src] ="content?.image_path" class="img-fluid">
							</div>
							<h5 class="mb-3">{{content?.heading}}</h5>
							<p>{{content?.content}}</p>
							<a href="{{content?.url}}" target="_blank">Continue>></a>
						</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Portfolio Section -->
	<div class="portfolio-wrapper section-gap">
		<div class="section-title-wrapper">
			<div class="row">
				<div class="col-sm-12 col-md-10 mx-auto text-center">
					<h2 class="text-black"> 会社概要</h2>
					<h6>当社は兵庫県で20年以上ミニミニFCを運営する不動産会社シティネット(株)が出資母体となり、シティネットグループが管理する物件の家賃管理や生活クレーム対応を行う会社として事業を開始いたしました。創業以来、シティネットで実施していた企業も含めて、外国籍の方の部屋探しについて、数多くの企業とお取引をさせていただいております。インドネシア・ネパール・バングラデシュ・ベトナム含む8ヶ国語対応可能スタッフが在籍しております。</h6>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="blog-alternate mb-5" *ngFor="let portfolio of portfolioV3">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<div class="overlay-wrap mb-3">
							<img [src] ="portfolio?.image_path" width="500" height="500" class="img-fluid border-rad w-100" alt="gallery images" />
							<a href="{{portfolio?.image_path}}" data-fancybox="images" class="card-img-overlay primary-tp-layer pos-center text-center">
								<span class="center-holder">
									<a class="ih-fade-down square-40 rounded-circle bg-white shadow-md">
										<i class="fa fa-plus align-middle"></i>
									</a>
								</span>
							</a>
						</div>
					</div>
					<div class="col-sm-12 col-md-6">
						<div class="portfolio-content py-4">
							<h5 class="badge {{portfolio?.title_color}} p-2 btn-pill"> {{portfolio?.title}} </h5>
							<h4 class="mb-3"><a href="javascript:void(0);">{{portfolio?.heading}}</a></h4>
							<p class="mb-3">名称 : オセロ・フィナンシャルサービス株式会社 </p>
							<p class="mb-3">資本金 : 3,000万円 </p>
							<p class="mb-3">役員 : 代表取締役 高橋 尚二郎 </p>
							<p class="mb-3">メール : backoffice@othello-fs.co.jp </p>
							<!-- <a href="{{portfolio?.read_more_url}}" class="btn btn-outline-secondary btn-sm btn-chk-dark"> READ MORE </a> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Pricing grid -->
	<!-- <div class="pricing-grid">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2 class="text-white"> Plans and Pricing  </h2>
					</div>
				</div>
			</div>
		  <div class="row">
				<div class="col-sm-12 col-md-4 col-lg-4 mb-4" *ngFor="let content of pricingContent">
					<div class="pricing-card border-0 pt-5 px-4 pb-4 border-rad">
						<span class="mb-4 {{content?.bg_color}} p-2 px-3 btn-pill text-center d-inline-block"> {{content?.home_title}} </span>
						<h2 class="font-4x px-2 fw-200 mb-4"><sup class="font-xs"> {{content?.currency}} </sup>
						{{content?.price}} <sub class="font-sm"> </sub></h2>
						<ul class="list-unstyled pricing-list mb-5">
							<li class="mb-3" *ngFor="let pricingList of content?.pricing_list">
								<i class="fa {{pricingList?.icon}} mr-2"></i>
								{{ pricingList?.value }}
							</li>
						</ul>
						<a routerLink="/{{content.url}}" class="btn btn-outline-secondary btn-pill btn-lg submit-btn btn-block">Buy Plan</a>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- Testimonial -->
	<!-- <div class="testimonial-section section-gap">
		<div class="">
			<div class="container">
				<div angly-testimonial [testimonialContent]="testimonial"></div>
			</div>
		</div>
	</div> -->
	<!-- Subscribe section -->
	<!-- <div angly-subscribe [subscribeFormClasses]="subscribeFormClasses"></div> -->
	<!-- Contact information section -->
	<div id="home-about" class="contact-sectionhosi">
		<div class="container">
			<div class="row" angly-homeContact [contact]="contact"></div>
		</div>
	</div>
	<!-- Send message section -->
	<div id="home-contactus" class="send-msg section-gap style-form">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 col-md-10 col-lg-7 mx-auto">
						<div angly-sendMessage></div>
					</div>
				</div>
			</div>
	</div>
</div>
