<!-- Mobile Feature -->
<div class="col-sm-12 col-md-6 text-center">
   <img src="assets/img/feature-mobile.png" alt="mobile feature" class="img-fluid" width="328" height="624">
</div>
<div class="col-sm-12 col-md-6 pt-5">
	<ul class="list-unstyled text-white">
		<li class="media mb-5" *ngFor="let featured of mobileFeatured">
			<span class="mr-5 d-flex">
				<i class="{{featured?.icon_class}} {{featured?.icon_path}} font-4x"></i>
			</span>
			<div class="media-body">
				<h5 class="mb-3 text-white">{{featured?.title}}</h5>
				<p>{{featured?.content}}</p>
			</div>
		</li>
	</ul>
</div>
