<!-- Subscribe-section -->
<div class="subscribe-section bg-green py-6">
   <div class="container">
      <div class="row">
         <div class="col-sm-12 col-md-9 mx-auto text-center">
            <div class="mb-5">
               <h2 class="font-2x text-white"> Subscribe Our Newsletters </h2>
            </div>
            <form class="d-block" [formGroup] = "subscribe" (ngSubmit) = "subscribeNow(subscribe.value)">
   				<div class="form-row justify-content-center">
   					<div class="form-group col-sm-12 col-md-6">
                     <input type="email" class="form-control w-100 form-control-lg" placeholder=" Enter Your Email" id="validationCustom01" formControlName = "email" required>
                     <span class="has-icon"><i class="ion-ios-mail"></i></span>
                     <div class="invalid-feedback">
                       Please provide a valid Email.
                     </div>
   					</div>
   					<div class="form-group col-sm-12 col-md-3">
   						<button type ="submit" class="btn btn-dark btn-block btn-lg submit-btn"> Subscribe</button>
   					</div>
   				</div>
   			</form>
         </div>
      </div>
   </div>
</div>
<!-- Subscribe-section Closed -->


